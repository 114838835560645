<template>
  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2">
        <a
          @click="navigate('minhas-turmas')"
          class="cursor-pointer text-indigo-600 hover:text-indigo-900"
          >Voltar para Minhas turmas</a
        >
      </div>

      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">
          Notas
          <br />
          <span v-if="loading" class="text-xl loading">Carregando</span>
          <span class="text-xl" v-if="group && !loading">{{
            `${group?.turma} - ${group?.ano}`
          }}</span>
        </h1>
        <button
          v-if="updateButtonVisible"
          @click="updateGrades"
          class="
            h-full
            cursor-pointer
            px-3
            py-2
            bg-gray-800
            ml-2
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          <svg
            v-if="updatingGrades"
            class="relative left-1/2 animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span v-if="!updatingGrades">Salvar</span>
        </button>
        <button
          @click="generateGrades"
          class="
            flex
            h-8
            cursor-pointer
            px-3
            py-2
            bg-gray-800
            ml-2
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          Imprimir
        </button>
      </div>

      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h3
          class="text-center w-full m-12"
          v-if="!loading && groupGrades.length === 0"
        >
          Não existem alunos cadastrados para essa turma
        </h3>

        <svg
          v-if="loading"
          class="
            relative
            left-1/2
            animate-spin
            -ml-1
            m-14
            h-10
            w-10
            text-indigo-500
          "
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>

        <table
          v-if="!loading && groupGrades.length !== 0"
          class="min-w-full divide-y divide-gray-200"
        >
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Aluno
              </th>
              <th>1.º bimestre</th>
              <th>2.º bimestre</th>
              <th>3.º bimestre</th>
              <th>4.º bimestre</th>
              <th>Final</th>
            </tr>
          </thead>

          <tbody v-if="loading">
            <tr>
              <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                <svg
                  class="
                    relative
                    left-1/2
                    animate-spin
                    -ml-1
                    mr-3
                    h-5
                    w-5
                    text-purple-600
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </td>
            </tr>
          </tbody>

          <tbody
            v-if="!loading && groupGrades.length !== 0"
            class="bg-white divide-y divide-gray-200"
          >
            <tr v-for="(grade, index) in groupGrades" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="text-md font-medium text-gray-900">
                    {{ getStudentName(grade.aluno) }}<br />
                    <small>{{ "Série: " + getSeriesName(grade.aluno) }}</small>
                    <small>{{
                      " | Ciclo: " + getCycleName(grade.aluno)
                    }}</small>
                  </div>
                </div>
              </td>
              <td
                v-for="(nota, i) in grade.notas"
                :key="i"
                class="
                  px-4
                  py-4
                  whitespace-nowrap
                  text-center text-sm
                  font-medium
                "
              >
                <input
                  type="number"
                  class="
                    text-center
                    rounded-lg
                    border-transparent
                    appearance-none
                    border border-gray-300
                    py-2
                    px-1
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  min="0"
                  max="10"
                  @change="sanitizeGrade(index, i)"
                  v-model="nota.value"
                />
              </td>
              <td
                class="
                  px-4
                  py-4
                  whitespace-nowrap
                  text-center text-sm
                  font-medium
                "
              >
                <input
                  type="number"
                  class="
                    text-center
                    rounded-lg
                    border-transparent
                    appearance-none
                    border border-gray-300
                    py-2
                    px-1
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  min="0"
                  max="10"
                  @change="sanitizeGrade(index, null)"
                  v-model="grade.notaFinal.value"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Firebase from "@/firebase";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
import { getCurrentInstance } from "vue";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../helpers/pdf_logo";

export default {
  setup() {
    const app = getCurrentInstance();
    const toast = app.appContext.provides.$toast;
    const route = useRoute();
    const group = ref(null);
    const loading = ref(true);
    const groupStudents = ref([]);
    const groupGrades = ref([]);
    const teacher = ref(null);

    const groupId = route.params.groupId;

    const seriesArray = [];
    const cyclesArray = [];

    const loadingConfig = ref(false);

    const getConfig = async () => {
      loadingConfig.value = true;
      const configSnapshot = await Firebase.getDoc("config/prod");
      const config = configSnapshot.data();

      config.series.forEach((serie) =>
        seriesArray.push({
          value: serie,
          label: serie.nome,
        })
      );

      config.ciclos.forEach((cycle) =>
        cyclesArray.push({
          value: cycle,
          label: cycle.nome,
        })
      );

      loadingConfig.value = false;
    };

    const getGroupAndStudentsAndGrades = async () => {
      loading.value = true;

      try {
        const groupSnapshot = await Firebase.getDoc(
          `${Firebase.collections.GROUPS}/${groupId}`
        );
        group.value = { ...groupSnapshot.data(), id: groupSnapshot.id };

        const teacherSnapshot = await Firebase.getDoc(
          `${Firebase.collections.TEACHERS}/${group.value.professor.id}`
        );
        teacher.value = { ...teacherSnapshot.data(), id: teacherSnapshot.id };

        const promises = [];

        group.value.alunos?.forEach((aluno) => {
          promises.push(
            Firebase.getDoc(Firebase.collections.STUDENTS + "/" + aluno)
          );
        });

        const studentsSnapshots = await Promise.all(promises);

        groupStudents.value = studentsSnapshots.map((snapshot) => ({
          id: snapshot.id,
          ...snapshot.data(),
        }));

        if (!group.value.avaliacoes || group.value.avaliacoes.length === 0) {
          const grades = groupStudents.value.map((s) => {
            return {
              aluno: Firebase.doc(Firebase.collections.STUDENTS + "/" + s.id),
              notas: [1, 2, 3, 4].map(() => ({ value: null })),
              notaFinal: { value: null },
            };
          });
          groupGrades.value = grades;
        } else {
          groupGrades.value = group.value.avaliacoes.map((g) => {
            return {
              aluno: g.aluno,
              notas: g.notas.map((n) => ({ value: n })),
              notaFinal: { value: g.notaFinal },
            };
          });
        }
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível carregar as notas para esa turma!",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        loading.value = false;
      }
    };

    const updateButtonVisible = ref(false);
    const setUpdateButtonVisibility = (newValue) => {
      updateButtonVisible.value = newValue;
    };

    const updatingGrades = ref(false);

    const updateGrades = async () => {
      updatingGrades.value = true;
      try {
        const grades = [];

        for (const g of groupGrades.value) {
          grades.push({
            aluno: g.aluno,
            notas: g.notas.map((n) => n.value),
            notaFinal: g.notaFinal.value,
          });
        }

        await Firebase.setDoc(Firebase.collections.GROUPS + "/" + groupId, {
          avaliacoes: grades,
        });

        toast.open({
          message: "Notas atualizadas com sucesso!",
          type: "success",
          duration: 2000,
          position: "bottom",
          dismissible: true,
        });
        setUpdateButtonVisibility(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível atualizar as notas para esta turma.",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        updatingGrades.value = false;
      }
    };

    const sanitizeGrade = (aluno, nota) => {
      if (!updateButtonVisible.value) setUpdateButtonVisibility(true);
      if (nota !== null) {
        const value = groupGrades.value[aluno].notas[nota].value;
        groupGrades.value[aluno].notas[nota].value =
          value < 0 ? 0 : value > 10 ? 10 : value;
      } else {
        const value = groupGrades.value[aluno].notaFinal.value;
        groupGrades.value[aluno].notaFinal.value =
          value < 0 ? 0 : value > 10 ? 10 : value;
      }
    };

    const getSeriesName = (aluno) => {
      const seriesId =
        groupStudents.value.find((s) => s.id === aluno.id)?.serie ?? null;
      const s = seriesArray.find((serie) => serie.value.id === seriesId);
      return s?.value?.nome || "Sem série";
    };

    const getCycleName = (aluno) => {
      const cycleId =
        groupStudents.value.find((s) => s.id === aluno.id)?.ciclo ?? null;
      const c = cyclesArray.find((cycle) => cycle.value.id === cycleId);
      return c?.value?.nome || "Sem ciclo";
    };

    const generateGrades = () => {
      const header = [
        { text: "#", style: "tableHeader" },
        { text: "Aluno", style: ["alignLeft", "tableHeader"] },
        { text: "1.º bimestre", style: ["alignLeft", "tableHeader"] },
        { text: "2.º bimestre", style: ["alignLeft", "tableHeader"] },
        { text: "3.º bimestre", style: ["alignLeft", "tableHeader"] },
        { text: "4.º bimestre", style: ["alignLeft", "tableHeader"] },
        { text: "Nota fiscal", style: ["alignLeft", "tableHeader"] },
      ];
      const body = [];

      groupStudents.value.forEach((student, index) => {
        const studentGrades = groupGrades.value.find((grade) => {
          return student.id === grade.aluno.id;
        });

        body.push([
          index,
          student.nome +
            ` (Série: ${getSeriesName(student)}, Ciclo: ${getCycleName(
              student
            )})`,
          ...studentGrades.notas.map((n) => n.value),
          studentGrades.notaFinal.value,
        ]);
      });

      const docDefinition = {
        pageOrientation: "landscape",
        pageMargins: 10,
        content: [
          {
            columns: [
              {
                svg: logo,
                width: 200,
                height: 100,
              },
              [
                {
                  text: `Turma: ${group.value.turma}`,
                  style: "header",
                  fontSize: 20,
                  margin: [0, 20, 0, 0],
                },
                {
                  text: `Professor(a): ${teacher.value.nome}`,
                  style: "subheader",
                  fontSize: 15,
                },
              ],
            ],
          },
          {
            margin: [0, 15, 0, 0],
            table: {
              headerRows: 1,
              body: [header, ...body],
            },
          },
        ],
        styles: {
          defaultStyle: {
            alignment: "center",
          },
          tableHeader: {
            bold: true,
          },
          alignLeft: {
            alignment: "left",
          },
        },
      };

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(docDefinition).download();
    };

    return {
      group,
      loading,
      groupStudents,
      getGroupAndStudentsAndGrades,
      groupGrades,
      setUpdateButtonVisibility,
      updateGrades,
      updatingGrades,
      updateButtonVisible,
      sanitizeGrade,
      generateGrades,
      getConfig,
      seriesArray,
      cyclesArray,
      getSeriesName,
      getCycleName,
    };
  },
  mounted() {
    this.getConfig();
    this.getGroupAndStudentsAndGrades();
  },
  methods: {
    navigate(name) {
      this.$router.push({ name });
    },
    getStudentName(aluno) {
      return (
        this.groupStudents.find((s) => s.id === aluno.id)?.nome ||
        "Aluno não identificado"
      );
    },
  },
};
</script>
